<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Add Social Media Profiles</h4>
        <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
          <i class="fal fa-times"></i>
        </router-link>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <button @click="$router.push({name: 'select_content'})"
                  class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </button>
          <div class="circles">
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
          </div>
          <button class="btn---cta btn-blue btn-round block-btn" @click="validateAndStoreSocialBlock()"
                  :disabled="isAddingBlock">
                            <span>
                                <template v-if="getBioLinkContentSectionAdd._id">
                                    Update Block
                                    <clip-loader v-if="isAddingBlock" class="inline-spinner" :size="'14px'"
                                                 :color="'#ffffff'"></clip-loader>
                                </template>
                                <template v-else>
                                    Add Block
                                    <clip-loader v-if="isAddingBlock" class="inline-spinner" :size="'14px'"
                                                 :color="'#ffffff'"></clip-loader>
                                </template>

                            </span>
          </button>

        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <p>Share all your social media profiles through the Social Icons Block.
              You can feature up to 5 of your own social media profiles.</p>
          </div>
          <bio-show-title-snippet></bio-show-title-snippet>
          <div class="mt-3 input_icon_text d-flex flex-column align-items-center  "
               :class="{'input-field-error': title_required || titleLength}">
            <div class="content">
              <div class="url">Add a Title</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getBioLinkContentSectionAdd.title" class="w-100" type="text"
                         :disabled="!getBioLinkContentSectionAdd.show_title"
                         placeholder="Add A Title..." data-cy="block-title">
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="title_required">Title is required.</span>
          <span class="input-error" v-else-if="titleLength">The title should not be greater than 100 characters.</span>

          <div class="mt-3 input_icon_text d-flex flex-column align-items-center">
            <div class="content">
              <div class="url">Customize Default Color</div>
              <div class="name">
                <input
                  v-model="getBioLinkContentSectionAdd.social_color"
                  type="color"
                />
              </div>
            </div>
          </div>
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <p class="block-heading font-weight-bold my-0">Select up to {{ limit + 1 }} social media platforms.</p>
                <div class="row pt-3 m-auto">
                  <div class="checkbox_input_image" v-if="!social.isDropdown" v-for="(social, key) in socialsChannelsList" :key="key">
                    <label :for="social.id" class="checkbox_right" v-tooltip="social.value" :data-cy="social.dataCy">
                      <button
                        type="button"
                        :class="[getBioLinkContentSectionAdd.channels[social.value] ? `selected_social_channel ${social.icon}` : `select_social_channels ${social.icon}`]"
                        class="btn p-3 ml-2 border bg-white"
                        style="height: 52px !important; width: 52px !important;"
                        @click="selectSocialChannel(social.value)"
                        :disabled="getBioLinkContentSectionAdd.channels_option.length > limit && getBioLinkContentSectionAdd.channels_option.indexOf(social.value) === -1"
                        :id="social.id"
                        :value="social.value">
                      </button>
                    </label>

                  </div>
                <div>

                  <b-dropdown :ref="'socialsChannelsList'"
                              class=" default_style_dropdown w-25">
                    <div slot="button-content">
                      <button
                        type="button"
                        style="height: 52px !important; width: 52px !important;"
                        class="p-3 ml-2 border rounded bg-white"
                      >    <span class="far fa-plus "></span>
                      </button>
                    </div>
                    <ul class="clear inner d-flex flex-wrap justify-content-between p-2">
                      <li :key="index" v-if="item.isDropdown" v-tooltip="item.value" v-for="(item, index) in socialsChannelsList"
                          class="pr-0" data-cy="camps">
                          <div class="content">
                            <div class="name">
                                <i class="p-3 mb-2 border rounded bg-white"
                                   style="height: 52px !important; width: 52px !important;"
                                   :class="item.icon"
                                   @click="selectSocialChannel(item.value)"
                                ></i>
                              </div>
                          </div>
                      </li>
                    </ul>
                  </b-dropdown>
                </div>
                </div>
              </div>
            </div>
          </div>

          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">

                <template v-for="(channel, index) in getBioLinkContentSectionAdd.channels_option">
                  <div class="  input_icon_text d-flex align-items-center mt-3"
                       :class="{'input-field-error': channelValidations[channel].url || channelValidations[channel].urlValid || channelValidations[channel].urlLength }">
                    <div class="avatar">
                      <i style="font-size: 24px;" class="snapchat fab" v-if="channel == 'snapchat'"
                         :class="[channelClass(channel), channel]"></i>
                      <i style="font-size: 24px;" class="fab" v-else
                         :class="[channelClass(channel), channel]"></i>
                    </div>

                    <div class="content">
                      <div class="url">{{ channelPlaceholder(channel) }}.</div>
                      <div class="name">
                        <div class="validation_input d-flex" data-cy="userid">
                          <span>{{ getChannelPlaceholder(channel) }}</span>
                          <input v-model="getBioLinkContentSectionAdd.channels[channel]" @input="checkURLValidation($event, channel)"
                                 class=""
                                 type="text" :placeholder="channelPlaceholder(channel)">
                          <i style="color: #3988FE" class="far fa-trash" v-tooltip.top-center="'Remove'"
                             @click="deleteChannel(channel)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="channelValidations[channel].url">{{ URLMessages.url }}</span>
                  <span class="input-error" v-else-if="channelValidations[channel].urlValid">{{ URLMessages.urlValid }}</span>
                  <span class="input-error" v-else-if="channelValidations[channel].urlLength">{{ URLMessages.urlLength }}</span>
                </template>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import {getDefaultSocialChannelsValidations} from '@/state/modules/bio-link'
import BioShowTitleSnippet from './short/BioShowTitleSnippet'
import { slugMessages, socialsChannelsList, URLMessages, URLValidations } from '@/common/attributes'

export default ({
  components: {
    BioShowTitleSnippet
  },
  data () {
    return {
      title_required: false,
      titleLength: false,
      validations: getDefaultSocialChannelsValidations(),
      isAddingBlock: false,
      socialsChannelsList: socialsChannelsList,
      limit: 4,
      URLMessages: {...URLMessages},
      channelValidations: {},
      customUrl: ''
    }
  },
  created () {
    this.isBioLinkFirstStepVerified()
    this.getBioLinkContentSectionAdd.channels_option.forEach(item => {
      this.channelValidations[item] = {...URLValidations}
    })
  },
  computed: {
    ...mapGetters([
      'getBioLinkContentSectionAdd'
    ])
  },
  methods: {
    /**
     * selecting the bio social channels
     * @param socialvalue
     */
    selectSocialChannel (socialvalue) {

      if (this.getBioLinkContentSectionAdd.channels_option.length >= 5) {
        this.$store.dispatch('toastNotification', {
          message: 'Social Icons selected cannot be greater than 5.',
          type: 'error'
        })
        return
      }

      if (!this.getBioLinkContentSectionAdd.channels_option.includes(socialvalue)) {
        this.channelValidations[socialvalue] = { ...URLValidations }
        this.getBioLinkContentSectionAdd.channels_option.push(socialvalue)
        this.getChannelPlaceholder(socialvalue)
      }
    },
    selectCustomChannel (customurl) {
      if (!this.getBioLinkContentSectionAdd.channels_option.includes(customurl)) {
        this.channelValidations[customurl] = { ...URLValidations }
        this.getBioLinkContentSectionAdd.channels_option.push(customurl)
        this.getChannelPlaceholder(customurl)
      }
    },
    getChannelPlaceholder (channel) {
      let Url = `https://${channel}.com/`

      if (channel === 'snapchat') {
        Url = `https://${channel}.com/add/`
      }
      if (channel === 'tiktok') {
        Url = `https://vm.${channel}.com/`
      }
      if (channel === 'linkedin') {
        Url = `https://${channel}.com/in/`
      }
      this.getBioLinkContentSectionAdd.channels[channel] = !this.getBioLinkContentSectionAdd.channels[channel] ? Url : this.getBioLinkContentSectionAdd.channels[channel]
    },
    /**
     * we will use this method to delete social channels from the list
     * @param channel
     */
    deleteChannel (channel) {
      delete this.getBioLinkContentSectionAdd.channels[channel]
      this.getBioLinkContentSectionAdd.channels_option = this.getBioLinkContentSectionAdd.channels_option.filter(e => e !== channel)
    },
    deleteCustomChannel (customurl) {
      delete this.getBioLinkContentSectionAdd.channels[customurl]
      this.getBioLinkContentSectionAdd.channels_option = this.getBioLinkContentSectionAdd.channels_option.filter(e => e !== customurl)
    },
    channelClass (name) {
      return `fa-${name}`
    },
    channelPlaceholder (name) {
      name = name.charAt(0).toUpperCase() + name.slice(1)
      return `Add ${name} username`
    },
    channelCustomPlaceholder () {
      return `Add custom channel`
    },
    customChannelPlaceholder () {
      return `Add username`
    },
    /**
     *  checking validations on channel url field
     * @param event
     * @param channel
     */
    checkURLValidation(event, channel) {
      this.channelValidations[channel].url = this.requiredCheck(this.getBioLinkContentSectionAdd.channels[channel])
      if (this.getBioLinkContentSectionAdd.channels[channel] && this.getBioLinkContentSectionAdd.channels[channel].length > 8) {
        this.getBioLinkContentSectionAdd.channels[channel] = this.urlConcatenation(this.getBioLinkContentSectionAdd.channels[channel])
        this.channelValidations[channel].urlValid = !this.isValidURL(this.getBioLinkContentSectionAdd.channels[channel])
        this.channelValidations[channel].urlLength = !this.maxLength(this.getBioLinkContentSectionAdd.channels[channel], 500)
      }
    },
    async validateAndStoreSocialBlock () {
      this.title_required = false
      if (this.getBioLinkContentSectionAdd.show_title) {
        this.title_required = this.requiredCheck(this.getBioLinkContentSectionAdd.title)
      }

      // checking the channel field validations
      let validateEachChannelField = {}
      for (const key in this.channelValidations) {
        validateEachChannelField[key] = !Object.keys(this.channelValidations[key]).every(k => this.channelValidations[key][k] === false)
      }

      if(!Object.keys(validateEachChannelField).every(k => validateEachChannelField[k] === false)) {
        this.$store.dispatch('toastNotification', {
          message: 'Social channel input fields are invalid!',
          type: 'error'
        })
        return
      }

      this.isAddingBlock = false

      // if the value validated and title required
      if (!this.title_required && !this.titleLength) {

        let res = await this.$store.dispatch('storeBioContentSection')
        if (res && res.data.status) {
          this.$router.push({name: 'select_content'})
        } else {
          this.alertMessage(res.data.message, 'error')
        }

      }
      this.isAddingBlock = false
    },
  },
  watch: {
    'getBioLinkContentSectionAdd.title' (value) {
      if (value && value.length > 0) {
        this.title_required = false
        this.titleLength = !this.maxLength(value, 100)
      }
    }
  }
})
</script>

<style lang="less" scoped>
.inline-spinner {
  display: inline-block;
  margin-left: 6px;
}

.block-btn:disabled {
  opacity: 0.7;
}

.bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
  width: 490px;
  text-align: center;
  display: inline-block;
}

.input_icon_text input {
  display: inline-block;
  width: 100%;
}

.snapchat {
  color: #fdfc02 !important;
}

.btn-none:enabled {
  border: 2px solid cornflowerblue !important;
}

.btn-none:disabled {
  border: 1px solid gray !important;
}

.select_social_channels {
  &:hover {
    background: whitesmoke !important;
    border: 1px solid #1d8bfa !important;
  }
}

.selected_social_channel {
  border: 1px solid #1d8bfa !important;
}


</style>
